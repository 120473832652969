import React from 'react';
import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { SEO } from 'components';

const TermsAndConditions = () => {
  return (
    <Wrapper>
      <SEO
        pageSpecificTitle="Terms And Conditions"
        pageSpecificDescription="Our Terms And Conditions"
        meta={[
          {
            name: 'robots',
            content: 'noindex'
          }
        ]}
      />
      <br />
      <h1>Agency X Terms And Conditions</h1>
      <b>Definition of our terminology as used within this document:</b>
      <br />
      <br />
      <p>
        A &apos;Project&apos; is any work undertaken or service provided by Agency X Limited for the
        Client on their request and as described in our confirmation order email to that client.
      </p>
      <p>
        A &apos;Client&apos; is a person, persons, business or organization using any of the
        services provided by Agency X Limited.
      </p>
      <p>
        &apos;Live Mode&apos; means the date the website, app, software or other marketing campaign
        is available to the Client. &apos;Domain&apos; is the website address as specified by the
        Client.
      </p>
      <p>
        &apos;Open Source Software&apos; is software made freely available to anyone under the GNU
        General Public License (GPL).
      </p>
      <p>&apos;Hosting&apos; is a yearly cost to keep a client&apos;s website activated online.</p>
      <p>&apos;Content&apos; is both text and images that the Client requires on the website.</p>
      <LinksList>
        <li>
          <AnchorLink to="#work-commencement" title="How We Use Data">
            Commencement of work
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="#payment-terms" title="How We Use Data">
            Payment Terms
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="#tip" title="How We Use Data">
            Transfer of Intellectual Property (IP)
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="#web-hosting" title="How We Use Data">
            Websites and Hosting
          </AnchorLink>
        </li>
      </LinksList>
      <DetailedList>
        <li>
          <div>
            <h4 id="work-commencement">Commencement of work</h4>
            <ol type="a">
              <li>
                <span>
                  The contract between Agency X Limited and the Client will be on these conditions,
                  to the exclusion of all other terms and conditions. Any variations to these
                  conditions shall have no effect unless agreed in writing.
                </span>
              </li>
              <br />
              <li>
                <span>
                  The works to be carried out shall be as set out in the Agency X Limited Sales
                  Order Confirmation.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited will only commence work on a project after receipt of a signed
                  Sales Order Confirmation.
                </span>
              </li>
            </ol>
          </div>
        </li>
        <li>
          <div>
            <h4 id="payment-terms">Payment Terms</h4>
            <ol type="a">
              <li>
                <span>
                  Agency X Limited operates on phased payments terms. These will be laid out in the
                  Agency X Limited Campaign Engagement Proposal and must be signed off as part of
                  any commercial agreement before Agency X Limited commences work on any project.
                  Agency X Limited will not commence work on the next phase of any project until the
                  previous phase invoice has been settled in full. Final phase settlement must be
                  made in full within 30 days of date of final invoice.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Email Marketing (Dot digital) clients, a license fee is payable in full prior to
                  account activation. Monthly bills will be issued in accordance with the clients
                  chosen rate card. Please note a minimum monthly fee of €25 applies. Annual renewal
                  license fee of 20% of initial chosen license will be applied.
                  <br />
                  Email Marketing Fixed contracts are set for a period of 12 months from
                  commencement date. Should a client not complete the full term of 12 months the
                  remaining charge for that 12-month period will apply. Notice to terminate a fixed
                  contract must be given in writing.
                </span>
              </li>
              <br />
              <li>
                <span>VAT is additional.</span>
              </li>
              <br />
              <li>
                <span>
                  Charges incurred to third parties for example for, but not limited to, the
                  purchase of media, manufactured items, printing, film production and the provision
                  of any other goods or services shall be passed on to the Client and be subject to
                  a handling charge.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Should work be suspended, cancelled or delayed at the request of the Client,
                  Agency X Limited reserve the right to charge for any services undertaken,
                  materials ordered and any reasonable related expenditures.
                </span>
              </li>
            </ol>
          </div>
        </li>
        <li>
          <div>
            <h4 id="tip">Transfer of Intellectual Property (IP)</h4>
            <span>
              Agency X Limited works to a &apos;finished works&apos; basis for all creative and
              original development projects. Projects are priced on the basis that the finished work
              and ownership therein transfers to the client once the final payment has been
              received.
              <br />
              During the creative process, component conceptual works IP, including 3rd party assets
              may be bought, licensed, filmed, photographed, drawn, or otherwise enhanced in full or
              in part to create the final finished work. This finished work is what is provided to
              the client, unencumbered to the best of our professional knowledge for their intended
              use.
              <br />
              Agency X as part of its service, keeps and maintains a master file of the creative for
              ongoing use in collaboration with our clients and for a reasonable period of time of
              circa 12 months from last usage, at the agency&apos;s discretion.
              <br />
              The process of creating any finished work, with all the above listed components
              remains the IP of Agency X.
              <br />
              <b>NOTE:</b> The upkeep of digital projects will be covered under a specific service
              level agreement (SLA).
              <br />
              <b>Non-transferable IP</b>
              <br />
              Agency X in its creations uses components and software with non- transferable licences
              beyond the finished works&apos; intended use.
              <br />
              These may include image files and fonts, original photography, or some other
              originators&apos; IP for which a specific license is paid.
              <br />
              Non-transferable IP currently in use by the agency includes:
              <br />
              Adobe Creative Suite for which fonts and some other items are non-transferrable beyond
              intended use - please see link&nbsp;
              <a
                href="https://helpx.adobe.com/fonts/using/font-licensing.html"
                target="_blank"
                rel="noreferrer">
                here
              </a>
              .
              <br />
              Shutterstock - for which image licences are non-transferable beyond intended use -
              please see link&nbsp;
              <a
                href="https://support.shutterstock.com/s/article/Are-licenses-transferrable?language=en_US"
                target="_blank"
                rel="noreferrer">
                here
              </a>
              .
              <br />
              <b>Allowable Transfer of IP</b>
              <br />
              Where a client requests the transfer of IP from Agency X beyond the finished work, and
              where the work itself is not overly compromised by the non-transferable licence
              component, the agency will provide a commercial price for client agreement, for the
              components it can share. This commercial price will be in line with industry best
              practice at the time and will include a cost for reworking the original file to remove
              where possible any non-transferrable IP components. The final price will be subject to
              agreement between agency and client but will be at a minimum equivalent to 100% the
              original price of a job to date for use in the market intended. For use beyond the
              original market intended a multiple of the original cost will be agreed.
              <br />
              For more information and best practice on IP issues please refer to:&nbsp;
              <a href="https://www.ipoi.gov.ie/en/law-practice/" target="_blank" rel="noreferrer">
                https://www.ipoi.gov.ie/en/law-practice/
              </a>
            </span>
            <br />
          </div>
        </li>
        <li>
          <div>
            <h4 id="web-hosting">Websites and Hosting</h4>
            <ol type="a">
              <li>
                <span>
                  Agency X Limited shall expect the Client to carry out sufficient research before
                  proceeding with a website. This will include checking that the
                  website/idea/business will operate legally. It is important that the website is
                  not in any way illegal.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Where images used on the website have been purchased by Agency X Limited on behalf
                  of the Client, these images are strictly for use on the website only. Agency X
                  Limited are not liable for misuse of these images by the Client or any other
                  person&apos;s copying, altering or distributing the images to individuals or other
                  organizations.
                </span>
              </li>
              <br />
              <li>
                <span>
                  One set of change requests are complimentary in the postproduction phase of any
                  website or application project, thereafter any change requests will be charged at
                  the applicable Agency X Limited rates.
                </span>
              </li>
              <br />
              <li>
                <span>
                  The complimentary design and programming revisions offered by Agency X Limited end
                  when the project has been completed as per the final postproduction change
                  requests. Design and programming changes beyond this point are subject to
                  additional charges.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited will host the website if the Client requires us to do so and on
                  receipt of full payment of our Hosting fees. In doing so, Agency X Limited will
                  endeavour to provide a reliable and professional service to the Client at all
                  times but do not guarantee that the website hosting will be available at all
                  times, especially in the event of a technical failure beyond our control.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited cannot be held responsible for anything adversely affecting the
                  Client&apos;s business operation, sales, or profitability that might be claimed is
                  a result of a service offered by Agency X.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Where asked to provide search engine optimisation for a Client, Agency X Limited
                  do not guarantee any specific placement or high ranking on search engines.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited do not take any responsibility for a client&apos;s website rank
                  on search engines. This also includes any potential website downtime that can
                  occur. When updating, changing, creating and hosting a client&apos;s website,
                  there could be a change in the websites search engine ranking.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited will endeavour to meet any given deadline, but do not guarantee
                  and are not bound in any way to complete the Project by this date. The expected
                  completion date provided by any employee of Agency X Limited is purely an
                  estimate.
                </span>
              </li>
              <br />
              <li>
                <span>
                  In the instance of websites and apps, the Client shall own all production deployed
                  website/app files upon final payment to Agency X Limited. Agency X Limited shall
                  maintain ownership of all source code and design files used to build the
                  production deployed website, unless previously agreed upon with the Client.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Domain names may be registered by Agency X Limited and in this instance will be
                  registered to the Agency X Limited current address. If the domain names are
                  registered to Agency X Limited, the Client is the legal owner of the domain once
                  payment has been received in full. If the client requests to have details changed
                  or the domain transferred elsewhere, Agency X Limited will do this within a
                  reasonable timeframe.
                </span>
              </li>
              <br />
              <li>
                <span>
                  It is the responsibility of the Client to renew their domain names when due. If a
                  domain name expires, Agency X Limited cannot be held liable for this. However,
                  Agency X Limited will make reasonable eort to contact the Client regarding domain
                  renewal.
                </span>
              </li>
              <br />
              <li>
                <span>
                  When a Client renews hosting with Agency X Limited, this also includes domain
                  renewal if the renewal is needed to keep the site functioning and was purchased as
                  part of the hosting package. If the Client does not renew the hosting, their
                  domain name could be made available to the public for purchase and Agency X
                  Limited cannot be held liable for this.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Renewal of hosting is due on a yearly basis. The date of renewal will be annually
                  from the date the website was made live. The hosting will not be renewed if Agency
                  X Limited cannot contact the Client or the Client requests for Agency X Limited to
                  not host this site. This will also affect the domain as per item 4n.
                </span>
              </li>
              <br />
              <li>
                <span>
                  The hosting renewal charge must be received within 10 days of the hosting expiry
                  date. Agency X Limited reserve the right to deactivate any website where the
                  hosting has expired and the Client has not paid the renewal charge. There will be
                  an admin fee set by Agency X Limited for reactivating the website/Hosting. Should
                  a Client wish to move hosting away from Agency X Limited or transfer a Domain name
                  away from Agency X Limited, a €50 admin charge will be issued, which must be paid
                  before the transfer takes place.
                </span>
              </li>
              <br />
              <li>
                <span>
                  If the Client does not use Agency X Limited&apos;s hosting services, then the
                  management and hosting of the domain and/or website are the full responsibility of the
                  Client.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited has no control of, or responsibility for, the content of
                  Clients&apos; websites. In no way does the textual or image based content of our
                  Client&apos;s web sites constitute Agency X Limited endorsement, or approval of
                  the website or the material contained within the website. Agency X Limited has not
                  verified any of the materials, images or information contained within our
                  Client&apos;s web sites and is not responsible for the content or performance of
                  these sites or for the Client&apos;s transactions with them. Agency X Limited may
                  provide links or references to our Client&apos;s websites solely for the
                  convenience of prospective customers and intends that the links it provides be
                  current and accurate, but does not guarantee or warrant that such links will point
                  to the intended Client site at all times.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited shall place a small text link on the footer of a Clients website
                  that simply states the website was designed by Agency X Limited and links to our
                  company website.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited are not liable for loss, damage or corruption to files or
                  information stored on its servers or individual PCs relating to a Client&apos;s
                  website. The Client is solely responsible for any information or files relating to
                  its website.
                </span>
              </li>
              <br />
              <li>
                <span>
                  If a Domain name is purchased by the Client through a company other than Agency X
                  Limited, the Client has full responsibility in making sure that the domain name is
                  renewed when due. Agency X Limited will not renew the Domain name when annual
                  Hosting renewal is due if the Domain name is purchased through a company other
                  than Agency X.
                </span>
              </li>
              <br />
              <li>
                <span>
                  Agency X Limited makes no claims that the contents of this website may be lawfully
                  viewed or downloaded outside the Republic of Ireland. Access to this website may
                  not be legal by certain persons or in certain countries. If this website is
                  accessed from outside of the Republic of Ireland, it is done at own risk and the
                  visitor is responsible for compliance with the relevant laws of the visitor&apos;s
                  jurisdiction. The terms and conditions of this website are governed by the laws of
                  the Republic of Ireland. Jurisdiction for any claims arising in respect of this
                  website&apos;s Content shall lie exclusively with the courts of the Republic of
                  Ireland. If any provision of these terms and conditions is found to be invalid by
                  any court having competent jurisdiction, the invalidity of such provision shall
                  not affect the validity of the remaining provisions of these terms and conditions,
                  which shall remain in full force and effect.
                </span>
              </li>
              <br />
            </ol>
          </div>
        </li>
      </DetailedList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--ax-beige);
  position: relative;
  padding: 2em;
  width: 100%;
  margin: 90px auto 0;
  max-width: 62.5rem;

  p {
    margin-bottom: 1em;
  }

  b {
    color: #1d1d1b;
  }

  a {
    color: #ce9200;
  }

  /* Hacky way of controlling the height of a br tag -
  https://stackoverflow.com/questions/7614928/change-br-height-using-css/28310011 */
  br {
    content: '';
    margin: 2em;
    display: block;
    font-size: 50%;
  }

  h1 {
    color: #1d1d1b;
    text-align: center;
    margin: 0 auto 1.5em;
  }

  li {
    font-size: 0.725rem;
    margin-left: 1em;
  }

  @media screen and (min-width: 48rem) {
    li {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 62.5rem) {
    li {
      font-size: 0.9rem;
    }
  }
`;

const LinksList = styled.ol`
  margin-bottom: 1.5em;
  padding-left: 0;

  a,
  li {
    color: #1d1d1b;
    outline: none;
  }
`;

const DetailedList = styled.ol`
  padding-left: 0;

  div {
    margin-left: 0.75em;
  }

  h4 {
    margin-bottom: 0.5em;
  }

  li div ol {
    padding-left: 0;
    list-style-position: outside;

    li:first-child {
      margin-top: 1.25em;
    }

    li:last-child {
      margin-bottom: 2em;
    }

    li {
      margin-left: 1em;
      padding-right: 0.5em;

      span {
        position: relative;
        left: 0.5em;
      }
    }
  }
`;

export default TermsAndConditions;
